<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>섹터관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2 >기본정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사명</label>
            <ul>
              <li>{{sectInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd ><label>회사코드</label>
            <ul>
              <li>{{ sectInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>로케이션명</label>
            <ul>
              <li>{{sectInfo.locaNm}}</li>
            </ul>
          </dd>
          <dd ><label>로케이션코드</label>
            <ul>
              <li>{{ sectInfo.locaCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>구역명</label>
            <ul>
              <li>{{sectInfo.whNm}}</li>
            </ul>
          </dd>
          <dd><label>구역코드</label>
            <ul>
              <li>{{sectInfo.whCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd ><label>섹터명</label>
            <ul>
              <li>{{ sectInfo.sectNm}}</li>
            </ul>
          </dd>
          <dd ><label>섹터코드</label>
            <ul>
              <li>{{ sectInfo.sectCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>위치</label>
            <ul>
              <li>{{sectInfo.sectLocation}}</li>
            </ul>
          </dd>
          <dd><label>위치 이미지</label>
            <ul>
              <li v-if="!!sectImgNm" class="link" @click="$refs.sectImgWindowRef.kendoWidget().center().open()">{{sectImgNm}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>면적(평)</label>
            <ul>
              <li>{{sectInfo.area}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>활성화여부</label>
            <ul>
              <li>{{sectInfo.activeYn === 'Y' ? '활성화' : '비활성화'}}</li>
            </ul>
          </dd>
      
          <dd><label>비고</label>
            <ul>
              <li>{{sectInfo.contents}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>등록</label>
            <ul>
              <li>{{sectInfo.frstRgstDt}} &nbsp | &nbsp {{sectInfo.frstRgstNm }}</li>
            </ul>
          </dd>
          <dd><label>최종수정</label>
            <ul>
              <li>{{sectInfo.lastMdfyDt}} &nbsp | &nbsp {{sectInfo.lastMdfyNm }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>상품재고정보</h2></div>
      <kendo-grid
          class="mt20"
          style="cursor: pointer; max-height: 368px"
          :data-source="stkGoodsList"
          :navigatable="false"
          :sortable-mode="'multiple'"
          :reorderable="true"
          :column-menu="false"
          :resizable="true"
          :selectable="'row'"
          :columns="gridColumns"
          :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
      </kendo-grid>

    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.push({ name: 'SectorManageList' })">목록</button>
        </li>
        <li class="w30">
          <button class="large_btn dgray"
                  @click="onClickDeleteSector">삭제</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="$router.push({name: 'SectorManageEdit' })">수정</button>
        </li>
      </ul>
    </div>

    <kendo-window
        ref="sectImgWindowRef"
        :title="'위치이미지'"
        :modal="true"
        style="display:none; min-height: 300px;"
    >
      <div v-if="!!sectInfo.imgFileId">
        <img :src='ApiConfig.efsDomain + "/efs/file/"+ sectInfo.imgFileId + "/" + sectInfo.imgFile[0].fileSeqNo'>
      </div>
    </kendo-window>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

export default {
  name   : 'SectorManageDetail',
  computed : {
    sectImgNm : function() {
      if(this.sectInfo?.imgFile?.length > 0){
        return this.sectInfo?.imgFile[0]?.orgFileNm
      }else return null
    }
  },
  created () {
    const vm = this
    vm.getSectorManageDetail()
  },
  methods : {
    getSectorManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/sect/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}/${vm.$route.params.whCd}/${vm.$route.params.sectCd}`)
      .then(function (response) {
          if (response.data.resultStatus.messageCode === '2000') {
            vm.sectInfo = response.data.resultData
            vm.stkGoodsList = EtnersCommonUtil.setRowNumber(response.data.resultData.stkGoodsResponseList)
          } else {
            kendo.alert(response.data.resultStatus.messageText)
          }
        },
      )
    },
    onClickDeleteSector : function() {
      const vm = this
      kendo.confirm('섹터를 삭제하시겠습니까?').done(function (){
        vm.deleteSector()
      })
    },
    deleteSector : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/sect/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}/${vm.$route.params.whCd}/${vm.$route.params.sectCd}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'SectorManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
    data   : function () {
      const vm = this
      const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
      const rightAttributes = { style: 'text-align: right;' }
      const centerAttributes = { style: 'text-align: center;' }
      return {
        ApiConfig : ApiConfig,
        sectInfo  : {},
        stkGoodsList : [],
        gridColumns : [
          {
            field: 'rowNumber',
            title: 'No',
            width: '3%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'itemNm',
            title: '품목',
            width: '8%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'goodsCd',
            title: '상품코드',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'goodsNm',
            title: '상품명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'renewGbnNm',
            title: '상품분류',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'cnt',
            title: '재고수량',
            width: '5%',
            headerAttributes: headerAttributes,
            attributes: rightAttributes,
            template: function (dataItem) {
              return EtnersCommonUtil.numberWithCommas(dataItem.cnt)
            }
          }
        ],
      }
    },
  }
</script>
